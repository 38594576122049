/* $scaffolding
 ------------------------------------------*/
 html {
  font-size: 14px;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

html,
body {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: auto;
  background-color: #f9f9f9;

}
[dir="ltr"] .flexSpacer {
  margin-right: auto !important;
  box-sizing: border-box;
}

[dir="rtl"] .flexSpacer {
  margin-left: auto !important;
  box-sizing: border-box;
}

/* Hyperlinks */
a {
  text-decoration: none;
  color: inherit;
}

a:focus,
a:hover {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
}

.pr-1 {
  padding-right: 1rem !important;
}

.px-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}